<template>
  <div class="edit-tel-box">
    <van-nav-bar title="更换手机号" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <div class="filed-box">
        <van-field
          v-model="userForm.mobile"
          name="新手机号"
          label="新手机号"
          required
          placeholder="新手机号"
          :rules="[{ required: true, message: '' }]"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="userForm.smsCaptcha"
          name="短信验证码"
          label="短信验证码"
          clearable
          required
          placeholder="输入短信验证码"
          :maxlength="6"
          :rules="[{ required: true, message: '' }]"
        />
        <span :class="{ 'no-style': intervalId }" @click="sendPhoneVerifyRegist">{{ sendNotice }}</span>
      </div>
      <div class="filed-box">
        <van-field
          v-model="userForm.captcha"
          name="图形验证码"
          label="图形验证码"
          clearable
          required
          placeholder="输入图形验证码"
          :rules="[{ required: true, message: '' }]"
        />
        <span>
          <img v-if="imgVisible" :src="imageCaptchaUrl" @click="init()" />
        </span>
      </div>
      <div class="btn-box">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { isPhoneNum } from '../../utils/tool';
export default {
  name: 'editTel',
  data() {
    return {
      userForm: {
        mobile: null, //手机号,自动等于用户名
        smsCaptcha: null, //手机验证码
        captcha: null, //图形验证码
        captchaHashing: null, //图形验证码hash值(从cookie中取)
      },
      intervalId: null,
      sendNotice: '发送验证码',
      accept: '',
      imgVisible: true,
      imageCaptchaUrl: null,
      smsTemplateType: 'LOGIN', //验证类型("REGIST"|"LOGIN")
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 绑定hash
      this.bindCaptchaHashing();
      // 刷新验证码图片
      this.refreshImageCaptcha();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 刷新验证码
    async refreshImageCaptcha() {
      this.imgVisible = false;
      await this.$nextTick(() => {
        this.imgVisible = true;
      });
    },
    //将captchaHashing绑定到form上.
    async bindCaptchaHashing() {
      var uuid = Math.random();
      this.imageCaptchaUrl = `${process.env.VUE_APP_BASE_URL}/selfhelpOfficialAccounts/captcha.png?id=${uuid}`;
      var captchaHashing = uuid;
      this.userForm.captchaHashing = captchaHashing;
    },
    //发送手机验证码
    sendPhoneVerifyRegist() {
      // 未到60s
      if (this.intervalId) {
        return;
      }
      // 校验手机号是否正确.
      if (!isPhoneNum(this.userForm.mobile)) {
        this.$toast('请输入正确的手机号!');
        return;
      }
      // 发送请求,获得手机验证码
      this.$http
        .post('/selfhelpOfficialAccounts/message/sms/send', {
          mobile: this.userForm.mobile,
          smsTemplateType: this.smsTemplateType,
        })
        .then((res) => {
          if (res.code === 200) {
            this.downTime();
            this.$toast('验证短信发送成功!');
          } else {
            this.$toast(res.message || '发送失败!');
          }
        });
    },
    // 倒记时60秒
    downTime() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      var time = 60;
      this.intervalId = setInterval(() => {
        time--;
        this.sendNotice = `再次发送(${time}s)`;
        if (time <= 0) {
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.sendNotice = `发送验证码`;
        }
      }, 1000);
    },
    onSubmit() {
      this.$dialog
        .confirm({
          message: '确定要修改么？',
        })
        .then(() => {
          this.$http
            .put('/selfhelpOfficialAccounts/users/change/mobile', this.userForm)
            .then(async (res) => {
              if (res.code === 200) {
                this.$toast('修改成功,正在刷新数据');
                let back = await this.$http.get(
                  '/selfhelpOfficialAccounts/relation'
                );
                if (back.code === 200) {
                  localStorage.setItem('userInfo', JSON.stringify(back.data));
                  this.onClickLeft();
                }
              } else {
                this.$toast(res.message || '修改失败');
              }
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-cell {
  flex: 1;
}
.van-cell::after {
  border-bottom: none;
}
.filed-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  border-bottom: 1px solid #ebedf0;
  img {
    width: 77px;
    height: 23px;
  }
  span {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #206cfe;
    margin-left: 16px;
  }
}
.btn-box {
  margin-top: 40px;
  padding: 0 96px;
}
</style>
