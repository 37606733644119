import { render, staticRenderFns } from "./editTel.vue?vue&type=template&id=1b5ec789&scoped=true&"
import script from "./editTel.vue?vue&type=script&lang=js&"
export * from "./editTel.vue?vue&type=script&lang=js&"
import style0 from "./editTel.vue?vue&type=style&index=0&id=1b5ec789&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5ec789",
  null
  
)

export default component.exports